import React, { useEffect, useState } from 'react';
import SingleBlogThumb from '../Components/SingleBlogThumb';
import bannerImage from '../Assets/img/Concern/banner1.png';
function Blogs(props) {

    // const blogData = [
    //     {
    //         blogImage: bannerImage,
    //         blogName: "1 Effective Skin Tag Removal Techniques at Neya Clinic",
    //         shortDescription: "Discover the most effective skin tag removal techniques offered at Neya Dermatology and Aesthetics. Learn about our advanced treatments, safety measures, and what to expect during and after the procedure.",
    //         videoURL: "xryCzu9yaCE",
    //         blogURL: "/blogs/effective-skin-tag-removal-techniques",
    //         metaTagBlogTitle: "Effective Skin Tag Removal Techniques at Neya Clinic",
    //         section: [
    //             {
    //                 title: "Introduction to Skin Tags",
    //                 description: "Skin tags are small, benign growths that can appear on various parts of the body. They are usually harmless but can be bothersome or unsightly. At Neya Clinic, we offer several effective techniques for safe and painless skin tag removal. Our dermatologists assess each case individually to recommend the most appropriate treatment method. Whether you have one or multiple skin tags, our goal is to provide a comfortable experience and optimal results."
    //             },
    //             {
    //                 title: "Causes of Skin Tags",
    //                 description: "Skin tags can develop due to a variety of reasons, including friction, obesity, and genetic factors. They commonly occur in areas where skin rubs against skin or clothing, such as the neck, armpits, and groin. Hormonal changes during pregnancy can also increase the likelihood of developing skin tags. Understanding the underlying causes helps in preventing their recurrence and allows us to provide targeted advice on lifestyle modifications and skin care routines."
    //             },
    //             {
    //                 title: "Treatment Options at Neya Clinic",
    //                 description: "At Neya Dermatology and Aesthetics, we offer several advanced skin tag removal techniques, including cryotherapy, laser removal, and surgical excision. Cryotherapy involves freezing the skin tag with liquid nitrogen, causing it to fall off after a few days. Laser removal uses focused light energy to remove the tag with minimal discomfort and scarring. Surgical excision, performed under local anesthesia, is ideal for larger or stubborn skin tags. Each method is chosen based on the patient's needs and the characteristics of the skin tags."
    //             },
    //             {
    //                 title: "Post-Removal Care",
    //                 description: "Proper aftercare is crucial for optimal healing and to prevent complications. After the procedure, our dermatologists provide detailed instructions on how to care for the treated area. This includes keeping the area clean and dry, avoiding direct sunlight, and applying any prescribed ointments or creams. It is also important to monitor the site for any signs of infection or unusual changes. Following these guidelines ensures a smooth recovery and minimizes the risk of scarring."
    //             },
    //             {
    //                 title: "Why Choose Neya Clinic?",
    //                 description: "Neya Dermatology and Aesthetics is renowned for its expertise in skin care and cosmetic treatments. Our team of experienced dermatologists ensures that each patient receives personalized care and the best possible results. We use state-of-the-art technology and adhere to the highest safety standards to deliver effective treatments with minimal downtime. Patient satisfaction is our top priority, and we strive to make every visit a positive experience. Choosing Neya Clinic means choosing quality, trust, and excellence in dermatological care."
    //             }
    //         ],
    //         faqs: {
    //             qa: [
    //                 {
    //                     q: "What is the cost of skin tag removal in Hyderabad?",
    //                     a: "The cost varies based on factors such as the size, number, and location of skin tags. Contact Neya Dermatology and Aesthetics for an accurate quote."
    //                 },
    //                 {
    //                     q: "Are the results of skin tag removal at Neya Dermatology and Aesthetics effective?",
    //                     a: "Yes, our treatments are highly effective and aim to minimize scarring. However, individual results may vary depending on the characteristics of the skin tags and the removal method."
    //                 },
    //                 {
    //                     q: "How many sessions are required to completely remove skin tags?",
    //                     a: "While most skin tags can be removed in one session, larger or multiple tags may require additional treatments. Your dermatologist will discuss this during your consultation."
    //                 },
    //                 {
    //                     q: "Is skin tag removal suitable for all skin types?",
    //                     a: "Yes, skin tag removal procedures are safe for all skin types when performed by experienced dermatologists. At Neya Dermatology and Aesthetics, treatments are tailored to your specific needs for optimal results."
    //                 }
    //             ]
    //         }
    //     },
    //     {
    //         blogImage: bannerImage,
    //         blogName: "2 Effective Skin Tag Removal Techniques at Neya Clinic",
    //         shortDescription: "Discover the most effective skin tag removal techniques offered at Neya Dermatology and Aesthetics. Learn about our advanced treatments, safety measures, and what to expect during and after the procedure.",
    //         videoURL: "xryCzu9yaCE",
    //         blogURL: "effective-skin-tag-removal-techniques",
    //         metaTagBlogTitle: "Effective Skin Tag Removal Techniques at Neya Clinic",
    //         intro: {
    //             "name": "Introduction",
    //             "video": "0uTOpKRMkzo",
    //             "desc": [
    //                 "Neya Dermatology and Aesthetics provides top-tier dermatological services with a commitment to safety and patient satisfaction.",
    //                 "Our highly experienced professionals utilize state-of-the-art techniques to deliver effective treatments with minimal discomfort.",
    //                 "We offer personalized care plans tailored to meet the unique needs of each patient, ensuring optimal results and a positive experience.",
    //                 "At Neya, we prioritize your overall well-being and strive to enhance your natural beauty through our comprehensive range of dermatological and aesthetic treatments."
    //             ]
    //         },
    //         section: [
    //             {
    //                 title: "Introduction to Skin Tags",
    //                 description: "Skin tags are small, benign growths that can appear on various parts of the body. They are usually harmless but can be bothersome or unsightly. At Neya Clinic, we offer several effective techniques for safe and painless skin tag removal. Our dermatologists assess each case individually to recommend the most appropriate treatment method. Whether you have one or multiple skin tags, our goal is to provide a comfortable experience and optimal results."
    //             },
    //             {
    //                 title: "Causes of Skin Tags",
    //                 description: "Skin tags can develop due to a variety of reasons, including friction, obesity, and genetic factors. They commonly occur in areas where skin rubs against skin or clothing, such as the neck, armpits, and groin. Hormonal changes during pregnancy can also increase the likelihood of developing skin tags. Understanding the underlying causes helps in preventing their recurrence and allows us to provide targeted advice on lifestyle modifications and skin care routines."
    //             },
    //             {
    //                 title: "Treatment Options at Neya Clinic",
    //                 description: "At Neya Dermatology and Aesthetics, we offer several advanced skin tag removal techniques, including cryotherapy, laser removal, and surgical excision. Cryotherapy involves freezing the skin tag with liquid nitrogen, causing it to fall off after a few days. Laser removal uses focused light energy to remove the tag with minimal discomfort and scarring. Surgical excision, performed under local anesthesia, is ideal for larger or stubborn skin tags. Each method is chosen based on the patient's needs and the characteristics of the skin tags."
    //             },
    //             {
    //                 title: "Post-Removal Care",
    //                 description: "Proper aftercare is crucial for optimal healing and to prevent complications. After the procedure, our dermatologists provide detailed instructions on how to care for the treated area. This includes keeping the area clean and dry, avoiding direct sunlight, and applying any prescribed ointments or creams. It is also important to monitor the site for any signs of infection or unusual changes. Following these guidelines ensures a smooth recovery and minimizes the risk of scarring."
    //             },
    //             {
    //                 title: "Why Choose Neya Clinic?",
    //                 description: "Neya Dermatology and Aesthetics is renowned for its expertise in skin care and cosmetic treatments. Our team of experienced dermatologists ensures that each patient receives personalized care and the best possible results. We use state-of-the-art technology and adhere to the highest safety standards to deliver effective treatments with minimal downtime. Patient satisfaction is our top priority, and we strive to make every visit a positive experience. Choosing Neya Clinic means choosing quality, trust, and excellence in dermatological care."
    //             }
    //         ],
    //         faqs: {
    //             qa: [
    //                 {
    //                     q: "What is the cost of skin tag removal in Hyderabad?",
    //                     a: "The cost varies based on factors such as the size, number, and location of skin tags. Contact Neya Dermatology and Aesthetics for an accurate quote."
    //                 },
    //                 {
    //                     q: "Are the results of skin tag removal at Neya Dermatology and Aesthetics effective?",
    //                     a: "Yes, our treatments are highly effective and aim to minimize scarring. However, individual results may vary depending on the characteristics of the skin tags and the removal method."
    //                 },
    //                 {
    //                     q: "How many sessions are required to completely remove skin tags?",
    //                     a: "While most skin tags can be removed in one session, larger or multiple tags may require additional treatments. Your dermatologist will discuss this during your consultation."
    //                 },
    //                 {
    //                     q: "Is skin tag removal suitable for all skin types?",
    //                     a: "Yes, skin tag removal procedures are safe for all skin types when performed by experienced dermatologists. At Neya Dermatology and Aesthetics, treatments are tailored to your specific needs for optimal results."
    //                 }
    //             ]
    //         }
    //     },
    //     {
    //         blogImage: bannerImage,
    //         blogName: "3 Effective Skin Tag Removal Techniques at Neya Clinic",
    //         shortDescription: "Discover the most effective skin tag removal techniques offered at Neya Dermatology and Aesthetics. Learn about our advanced treatments, safety measures, and what to expect during and after the procedure.",
    //         videoURL: "xryCzu9yaCE",
    //         blogURL: "effective-skin-tag-removal-techniques",
    //         metaTagBlogTitle: "Effective Skin Tag Removal Techniques at Neya Clinic",
    //         intro: {
    //             "name": "Introduction",
    //             "video": "0uTOpKRMkzo",
    //             "desc": [
    //                 "Neya Dermatology and Aesthetics provides top-tier dermatological services with a commitment to safety and patient satisfaction.",
    //                 "Our highly experienced professionals utilize state-of-the-art techniques to deliver effective treatments with minimal discomfort.",
    //                 "We offer personalized care plans tailored to meet the unique needs of each patient, ensuring optimal results and a positive experience.",
    //                 "At Neya, we prioritize your overall well-being and strive to enhance your natural beauty through our comprehensive range of dermatological and aesthetic treatments."
    //             ]
    //         },
    //         section: [
    //             {
    //                 title: "Introduction to Skin Tags",
    //                 description: "Skin tags are small, benign growths that can appear on various parts of the body. They are usually harmless but can be bothersome or unsightly. At Neya Clinic, we offer several effective techniques for safe and painless skin tag removal. Our dermatologists assess each case individually to recommend the most appropriate treatment method. Whether you have one or multiple skin tags, our goal is to provide a comfortable experience and optimal results."
    //             },
    //             {
    //                 title: "Causes of Skin Tags",
    //                 description: "Skin tags can develop due to a variety of reasons, including friction, obesity, and genetic factors. They commonly occur in areas where skin rubs against skin or clothing, such as the neck, armpits, and groin. Hormonal changes during pregnancy can also increase the likelihood of developing skin tags. Understanding the underlying causes helps in preventing their recurrence and allows us to provide targeted advice on lifestyle modifications and skin care routines."
    //             },
    //             {
    //                 title: "Treatment Options at Neya Clinic",
    //                 description: "At Neya Dermatology and Aesthetics, we offer several advanced skin tag removal techniques, including cryotherapy, laser removal, and surgical excision. Cryotherapy involves freezing the skin tag with liquid nitrogen, causing it to fall off after a few days. Laser removal uses focused light energy to remove the tag with minimal discomfort and scarring. Surgical excision, performed under local anesthesia, is ideal for larger or stubborn skin tags. Each method is chosen based on the patient's needs and the characteristics of the skin tags."
    //             },
    //             {
    //                 title: "Post-Removal Care",
    //                 description: "Proper aftercare is crucial for optimal healing and to prevent complications. After the procedure, our dermatologists provide detailed instructions on how to care for the treated area. This includes keeping the area clean and dry, avoiding direct sunlight, and applying any prescribed ointments or creams. It is also important to monitor the site for any signs of infection or unusual changes. Following these guidelines ensures a smooth recovery and minimizes the risk of scarring."
    //             },
    //             {
    //                 title: "Why Choose Neya Clinic?",
    //                 description: "Neya Dermatology and Aesthetics is renowned for its expertise in skin care and cosmetic treatments. Our team of experienced dermatologists ensures that each patient receives personalized care and the best possible results. We use state-of-the-art technology and adhere to the highest safety standards to deliver effective treatments with minimal downtime. Patient satisfaction is our top priority, and we strive to make every visit a positive experience. Choosing Neya Clinic means choosing quality, trust, and excellence in dermatological care."
    //             }
    //         ],
    //         faqs: {
    //             qa: [
    //                 {
    //                     q: "What is the cost of skin tag removal in Hyderabad?",
    //                     a: "The cost varies based on factors such as the size, number, and location of skin tags. Contact Neya Dermatology and Aesthetics for an accurate quote."
    //                 },
    //                 {
    //                     q: "Are the results of skin tag removal at Neya Dermatology and Aesthetics effective?",
    //                     a: "Yes, our treatments are highly effective and aim to minimize scarring. However, individual results may vary depending on the characteristics of the skin tags and the removal method."
    //                 },
    //                 {
    //                     q: "How many sessions are required to completely remove skin tags?",
    //                     a: "While most skin tags can be removed in one session, larger or multiple tags may require additional treatments. Your dermatologist will discuss this during your consultation."
    //                 },
    //                 {
    //                     q: "Is skin tag removal suitable for all skin types?",
    //                     a: "Yes, skin tag removal procedures are safe for all skin types when performed by experienced dermatologists. At Neya Dermatology and Aesthetics, treatments are tailored to your specific needs for optimal results."
    //                 }
    //             ]
    //         }
    //     }
    // ];

    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        fetch("https://neyaclinic.com/api/blogs/", {
            method: 'get',
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setBlogData(data.data);
                data.data.map(o => o.blogImage = bannerImage)
            })
            .catch((err) => {
                setBlogData([
                    {
                        blogImage: require("../Assets/img/Header/Elite Dermatique Exclusive.png"),
                        blogName: "Understanding Acne: Causes, Treatments, and Prevention at Neya Clinic",
                        shortDescription: "Explore the comprehensive guide to acne provided by Neya Dermatology and Aesthetics. Acne, a common skin condition affecting people of all ages, can be a source of frustration and self-consciousness. This guide delves into the underlying causes of acne, from hormonal imbalances and genetic predispositions to lifestyle factors and environmental influences. It also offers insights into the most effective treatments available at Neya Clinic, including advanced dermatological procedures and personalized skincare regimens. Additionally, discover preventative measures that can help you maintain clear, healthy skin, reducing the frequency and severity of breakouts. Whether you're dealing with occasional pimples or persistent acne, Neya Dermatology and Aesthetics provides the expertise and care needed to achieve optimal skin health.",
                        videoURL: "RJUjN7DAwiY",
                        blogURL: "/blogs/understanding-acne-causes-treatments-prevention",
                        metaTagBlogTitle: "Understanding Acne: Causes, Treatments, and Prevention at Neya Clinic",
                        section: [
                            {
                                title: "Introduction to Acne",
                                description: "Acne is a common skin condition that affects millions of people worldwide. It occurs when hair follicles become clogged with oil and dead skin cells, leading to pimples, blackheads, and whiteheads. At Neya Clinic, we understand the physical and emotional impact acne can have on individuals of all ages. Our expert dermatologists are committed to providing personalized care and effective treatments to help you achieve clear and healthy skin. We offer a range of advanced therapies tailored to your unique skin type and condition, ensuring optimal results and long-term satisfaction."
                            },
                            {
                                title: "Causes of Acne",
                                description: "Acne can be triggered by various factors, including hormonal changes, genetic predisposition, and lifestyle choices. Hormonal fluctuations, especially during puberty, pregnancy, or menstrual cycles, can increase oil production in the skin, leading to acne breakouts. Genetics also play a significant role; if your parents had acne, you are more likely to experience it as well. Additionally, lifestyle factors such as a poor diet, stress, and improper skincare routines can exacerbate acne. Understanding these causes is crucial for developing a targeted treatment plan that addresses the root of the problem."
                            },
                            {
                                title: "Treatment Options at Neya Clinic",
                                description: "At Neya Dermatology and Aesthetics, we offer a comprehensive range of acne treatments designed to cater to different skin types and severity levels. Our treatments include topical and oral medications, chemical peels, laser therapy, and blue light therapy. Topical treatments often contain ingredients like benzoyl peroxide, salicylic acid, and retinoids, which help to reduce inflammation and unclog pores. Oral medications, such as antibiotics and hormonal treatments, may be prescribed for more severe cases. Chemical peels and laser therapies target acne scars and hyperpigmentation, promoting smoother and clearer skin. Our dermatologists work closely with you to determine the most suitable treatment plan, ensuring safe and effective results."
                            },
                            {
                                title: "Preventative Measures",
                                description: "Preventing acne involves adopting a consistent skincare routine and making lifestyle changes. Regular cleansing with a gentle, non-comedogenic cleanser helps to remove excess oil and dirt from the skin. Exfoliating weekly can also prevent the buildup of dead skin cells that can clog pores. It is important to avoid picking or squeezing pimples, as this can lead to scarring and further inflammation. Additionally, maintaining a balanced diet, managing stress, and getting adequate sleep can significantly improve skin health. At Neya Clinic, we provide personalized advice and skincare recommendations to help you maintain clear and healthy skin in the long run."
                            },
                            {
                                title: "Why Choose Neya Clinic?",
                                description: "Neya Dermatology and Aesthetics is a leading dermatology clinic known for its expertise and dedication to patient care. Our team of experienced dermatologists utilizes the latest technology and treatment methods to address various skin concerns, including acne. We believe in a holistic approach, combining medical treatments with lifestyle and skincare advice to achieve the best possible outcomes. Patient satisfaction is our top priority, and we strive to provide a comfortable and supportive environment for all our clients. Choosing Neya Clinic means choosing quality care, advanced treatments, and a commitment to your skin's health and beauty."
                            }
                        ],
                        FAQ: [
                            {
                                q: "What is the cost of acne treatment at Neya Clinic?",
                                a: "The cost of acne treatment varies depending on the specific treatment plan and the severity of your condition. Contact Neya Dermatology and Aesthetics for a detailed consultation and accurate pricing."
                            },
                            {
                                q: "How long does it take to see results from acne treatment?",
                                a: "The time to see results can vary depending on the treatment method and the individual's skin type. Some treatments may show improvements within a few weeks, while others may take several months for optimal results."
                            },
                            {
                                q: "Are acne treatments at Neya Clinic safe?",
                                a: "Yes, all acne treatments at Neya Clinic are performed by experienced dermatologists using safe and effective methods. We tailor our treatments to your specific needs to ensure the best possible outcomes with minimal side effects."
                            },
                            {
                                q: "Can acne be completely cured?",
                                a: "While acne can be effectively managed and controlled with appropriate treatments, it may not be completely curable for everyone. Our goal at Neya Clinic is to minimize breakouts and improve skin health for long-term benefits."
                            }
                        ]
                    }]);
            }, [])
    }, []);

    return (
        <div>
            <section class="page-title p-block-50 text-center">
                <div class="container">
                    <div class="row">
                        <div class="col-12 ">
                            <div class="breadcrumb_content">
                                <h2 class="breadcrumb_content">
                                    Blogs 
                                </h2>
                                <div class="breadcrumb-list">
                                    <span>
                                        <a href="/">Home </a>
                                    </span>
                                    <span class="divider">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </span>
                                    <span class="breadcrumb-list-active">
                                        <a> Blogs</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="p-block-100">
                <div class="container">
                    <div class="row">
                        {
                            blogData?.map((data, i) => <SingleBlogThumb data={data} key={i} />)
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blogs;