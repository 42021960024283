import React, { Suspense } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useState } from 'react';

import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import FooterStrip from './Components/FooterStrip';

import './App.css';
import './Assets/css/style.css';
import './Assets/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BlogDetails from './Pages/BlogDetails';
import Blogs from './Pages/Blogs';
// import Appointment from './Pages/Appointment';

// const Home = React.lazy(() => import('./Pages/Home'));
// const About = React.lazy(() => import('./Pages/About'));
// const Appointment = React.lazy(() => import('./Pages/Appointment'));
// const Contact = React.lazy(() => import('./Pages/Contact'));
// const ServicesDetails = React.lazy(() => import('./Pages/ServicesDetails'));
// const Services = React.lazy(() => import('./Pages/Services'));
const Admin = React.lazy(() => import('./Pages/Admin'));
// const ConcernDetails = React.lazy(() => import('./Pages/ConcernDetails'));
const LoginFormComponent = React.lazy(() => import('./Pages/LoginFormComponent'));
// const ExclusiveServiceDetails = React.lazy(() => import('./Pages/ExclusiveServiceDetails'));
// const VirtualTour = React.lazy(() => import('./Pages/VirtualTour'));

const App = () => {
    const location = useLocation();
    const isHome = location.pathname === '/';
    const isTour = location.pathname === '/virtualTour';

    if(!location.pathname.includes("admin")) {
        localStorage.removeItem("token");
    }

    const [isLoggedIn, setLoginState] = useState(false);

    const handleLoginState = (state) => {
        setLoginState(state);
    }

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop />
                {/* {!isTour && <Navbar isHome={isHome} />} */}
                <Routes>
                    <Route exact path="/" element={<Admin loginState={handleLoginState} />} />
                    <Route exact path="/admin" element={<Admin loginState={handleLoginState} />} />
                    <Route exact path="/admin/login" element={<LoginFormComponent loginState={handleLoginState} />} />

                    {/* <Route exact path="/about" element={<About />} />
                    <Route exact path="/virtualTour" element={<VirtualTour />} />
                    <Route exact path="/appointment" element={<Appointment />} />
                    <Route exact path="/services" element={<Services />} />
                    <Route exact path="/services/:serviceId" element={<ServicesDetails />} />
                    <Route exact path="/specialPackages/:exclusiveServiceId" element={<ExclusiveServiceDetails />} />
                    <Route exact path="/contact" element={<Contact />} />
                    <Route exact path="/blogs" element={<Blogs />} />
                    <Route exact path="/blogs/:blogId" element={<BlogDetails />} />

                    <Route exact path="/:concernId" element={<ConcernDetails />} /> */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                {/* {
                    !isTour &&
                    <>
                        <Footer />
                        <FooterStrip />
                    </>
                } */}
            </Suspense>
        </>
    );
}

export default App;
