import React from 'react';
import { NavLink } from 'react-router-dom';

function NeyaButton({title, link, width, margin, classNames}) {
    return (
        <div className={classNames} >
            <NavLink className="btn primary-btn procedure-vd-btn" to={link} role="button" style={{width:width, margin:margin}}>{title}</NavLink>
        </div>
    );
}

export default NeyaButton;