import React from 'react';
import NeyaButton from './NeyaButton';
import ButtonCustom from '../UI/ButtonCustom';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function SingleBlogThumb({ data, isAdmin = false, onEditClick, onAdminBlogDeleteClick }) {

    function onAdminEditClick() {
        onEditClick(data);
    }

    function onDeleteClick() {
        onAdminBlogDeleteClick(data.blogId);
        // console.log(data.blogId)
    }

    return (
        <div class="col-12 col-md-4 p-inline-30 mb-5">
            <div class="blog_img">
                <img width={"100%"} src={data.blogImage} alt="" />
            </div>
            <div class="mt-25">
                <h4>
                    <strong>{data.blogName}</strong>
                </h4>
                <p class="section-sub-heading">
                    <strong>{data.date}</strong>
                </p>
            </div>
            <div class="separator mt-25"></div>
            <div class="mt-25 blog-para service-para">
                <p>
                    {data.shortDescription}
                </p>
            </div>
            <div class="blog_btn mt-25">
                {/* <ButtonCustom text={'Read More'} link={data.blogURL} /> */}
                {!isAdmin &&
                    <NavLink to={data.blogURL} state={data}>
                        <a class="btn primary-btn book-appointment-btn w-100" role="button">Read More</a>
                    </NavLink>
                }
                {
                    isAdmin &&
                    <>
                        <div className='row'>
                            {/* <NavLink onClick={onAdminEditClick} className="col-md-6">
                            <a href='#blogUploadForm' class="btn primary-btn book-appointment-btn w-100" role="button">Edit</a>
                        </NavLink> */}
                            <a href='#blogUploadForm' onClick={onAdminEditClick} className="col-md-6 btn primary-btn book-appointment-btn" style={{ "border": "1px solid var(--secondary-color)"}}>
                                Edit
                            </a>
                            <a onClick={onDeleteClick} className="col-md-6 btn primary-btn book-appointment-btn" style={{ "border": "1px solid var(--secondary-color)"}}>
                                Delete
                            </a>
                            {/* // <NavLink onClick={onDeleteClick} className="col-md-6 btn primary-btn book-appointment-btn w-100"">
                            //     <a class="btn primary-btn book-appointment-btn w-100" role="button">Delete</a>
                            // </NavLink> */}
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default SingleBlogThumb;